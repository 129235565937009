import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllVendorRequests extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('VendorRequest/GetAll')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class IsSubDomainExist extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('VendorRequest/IsShopSubdomainExist')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class UpdateVendorRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('VendorRequest/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class VendorRequestGetById extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('VendorRequest/GetBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class VendorRequestDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('VendorRequest/DeleteBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class VendorRequestUpdateFilesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('VendorRequest/UpdateFiles')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

export {
    GetAllVendorRequests,
    UpdateVendorRequest,
    VendorRequestGetById,
    VendorRequestDeleteRequest,
    VendorRequestUpdateFilesRequest
};
