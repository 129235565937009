<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-modal
        id="modal-delete"
        cancelTitle="خیر"
        centered
        ok-title="بله"
        @ok="DeleteRequest"
    >
      <span>آیا از حذف کردن این درخواست اطمینان دارید ؟ </span>
    </b-modal>

    <b-card
        v-if="shopRequests !== null"
        class="mb-0"
    >
      <b-row class="my-1">
        <b-col cols="12">
          <h1>درخواست های ایجاد فروشگاه</h1>
        </b-col>
        <b-col cols="12" md="3" class="ml-auto">
          <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
        </b-col>
      </b-row>
      <b-table
          ref="refUserListTable"
          :fields="myTableColumns"
          :items="shopRequests"
          bordered
          class="position-relative"
          empty-text="موردی موجود نیست!"
          primary-key="id"
          :sort-by.sync="sortBy"
          responsive
          show-empty
          striped
      >

        <template #cell(status)="data">

          <b-badge v-if="data.item.status === 1" variant="secondary">
            در انتظار

          </b-badge>
          <b-badge v-if="data.item.status === 2" variant="success">
            تایید شده

          </b-badge>
          <b-badge v-if="data.item.status === 3" variant="danger">
            رد شده

          </b-badge>


        </template>

        <template #cell(shopSubdomain)="data">
          <a :href="returnUrl+data.item.userInfo.userName" target="_blank">
          <span>
            {{ data.item.userInfo.userName }}
          </span>
          </a>
        </template>

        <template #cell(userInfo.userId)="data">

          <span>
            {{ data.item.userInfo.firstName }} {{ data.item.userInfo.lastName }}
          </span>
        </template>

        <!-- Column: view -->
        <template #cell(operation)="data">
          <div class="d-flex align-items-center justify-content-between">

            <b-link :to="{ name: 'pages-shop-ShopRequestSingle', params: { id: data.item.vendorRequestId }}"
                    class="cursor-pointer d-flex flex-row"
            >
              <feather-icon class="text-primary" icon="ExternalLinkIcon" size="25"/>
            </b-link>
            <div v-b-modal.modal-delete
                 class="cursor-pointer d-flex flex-row"
                 @click="showDelete(data.item.vendorRequestId)"
            >
              <feather-icon class="text-danger" icon="TrashIcon" size="25"/>
            </div>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              sm="12"-->
          <!--              class="d-flex align-items-center justify-content-end "-->
          <!--          >-->

          <!--            <b-pagination-->
          <!--                v-model="currentPage"-->
          <!--                :total-rows="totalCount"-->
          <!--                :per-page="perPage"-->
          <!--                first-number-->
          <!--                last-number-->
          <!--                class="mb-0 mt-1 mt-sm-0"-->
          <!--                prev-class="prev-item"-->
          <!--                next-class="next-item"-->
          <!--            >-->
          <!--              <template #prev-text>-->
          <!--                <feather-icon style="transform: rotate(180deg)"-->
          <!--                              icon="ChevronLeftIcon"-->
          <!--                              size="18"-->
          <!--                />-->
          <!--              </template>-->
          <!--              <template #next-text>-->
          <!--                <feather-icon-->
          <!--                    icon="ChevronRightIcon"-->
          <!--                    size="18"-->
          <!--                />-->
          <!--              </template>-->
          <!--            </b-pagination>-->

          <!--          </b-col>-->

        </b-row>
      </div>

    </b-card>

  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {UpdateVendorRequest, GetAllVendorRequests, VendorRequestDeleteRequest} from "@/libs/Api/shopRequests";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'درخواست های ایجاد فروشگاه  - پنل ادمین انزا مد',
  name: 'ProductsCategory',
  data() {
    return {
      pic: null,
      baseURL: Helper.baseUrl,
      returnUrl: Helper.returnUrl,
      showOverlay: false,
      shopRequests: null,
      showUpdateModal: false,
      totalCount: null,
      sortBy: '',
      selectedToDelete: null,
      showDeleteModal: false,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'vendorRequestId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'userInfo.userId',
          label: 'نام درخواست دهنده',
          sortable: true
        },
        {
          key: 'shopSubdomain',
          label: 'دامین فروشگاه',
          sortable: true
        },
        {
          key: 'companyName',
          label: 'نام فروشگاه',
          sortable: true
        },
        {
          key: 'status',
          label: 'وضعیت',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

      ],
      currentPage: 1,
      perPage: 10,
      search: '',
    }
  },
  async created() {
    await this.getAlLShopRequests()
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  watch: {
    search: function () {
      this.getAlLShopRequests()
    },
    currentPage: function () {
      this.getAlLShopRequests()
    }
  },
  methods: {
    async getAlLShopRequests() {
      let _this = this;
      _this.showOverlay = true;
      let getAllVendorRequests = new GetAllVendorRequests(_this);
      let data = {
        pageNumber: 0,
        search: _this.search,
        count: 0,
        parentId: 0
      }
      getAllVendorRequests.setParams(data)
      await getAllVendorRequests.fetch(function (content) {
        _this.shopRequests = content.data.requests;
        _this.totalCount = content.data.requestsCount;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async DeleteRequest() {
      let _this = this;
      _this.showOverlay = true;
      let vendorRequestDeleteRequest = new VendorRequestDeleteRequest(_this);
      let data = {
        id: _this.selectedToDelete
      }
      vendorRequestDeleteRequest.setParams(data)
      await vendorRequestDeleteRequest.fetch(function (content) {
        _this.getAlLShopRequests();
        _this.selectedToDelete = null;
        this.showDeleteModal = false;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    showDelete(id) {
      this.showDeleteModal = true;
      this.selectedToDelete = id;
    }
  },
}
</script>

<style scoped>

</style>
